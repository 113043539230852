<template>
  <div v-loading="permissionsLoading">
    <div class="mt-10">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Delete rule" name="delete_rule"></el-tab-pane>
        <el-tab-pane label="Edit rule" name="edit_rule"></el-tab-pane>
      </el-tabs>
      <el-scrollbar
        wrap-style="max-height: 60vh;"
        v-if="activeName == 'delete_rule'"
      >
        <el-row>
          <span
            >{{ currentEntity.name }} data will be not deleted if
            <el-select
              v-model="deleteCondition"
              placeholder="Select type"
              size="mini"
            >
              <el-option value="OR" label="any one"> </el-option>
              <el-option value="AND" label="all"> </el-option> </el-select
          > Condition Satisfy</span>
          <el-button
            class="float-right mr-3 mb-2"
            @click="addRule"
            type="primary"
            size="mini"
          >
            Add rule
          </el-button>
        </el-row>
        <el-row
          v-for="(dRule, i) in deleteRules"
          :key="'rule' + i"
          :gutter="30"
          class="m-tb-2"
        >
          <el-col :span="4">
            Type
            <el-select
              v-model="dRule.type"
              placeholder="Select type"
              size="mini"
            >
              <el-option value="FIELD" label="Field type"> </el-option>
              <!-- <el-option value="RELATIONSHIP" label="Relationship type">
              </el-option> -->
            </el-select>
          </el-col>
          <el-col :span="4" v-if="dRule.type == 'FIELD'">
            Field
            <el-select
              v-model="dRule.field"
              placeholder="Select field"
              size="mini"
            >
              <el-option
                v-for="(op, i) in entityFields"
                :key="i + '_fields'"
                :value="op.p_entity_id + '#' + op.key"
                :label="op.label"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-else-if="dRule.type == 'RELATIONSHIP'">
            Relationship
            <el-select
              v-model="dRule.relation"
              placeholder="Select relation"
              size="mini"
            >
              <el-option
                v-for="(op, i) in entityRelations"
                :key="i + '_relationship'"
                :value="op.relationshiop_id"
                :label="op.relationshiop_title"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" v-if="dRule.type">
            Custom Message
            <el-input v-model="dRule.message" size="mini" placeholder="Message">
            </el-input>
          </el-col>
          <el-col :span="6" v-if="dRule.type">
            Bulk delete message
            <el-input v-model="dRule.bulk_delete_message" size="mini" placeholder="Message">
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-link
              type="danger"
              class="mt-3"
              :underline="false"
              @click="deleteRule(i)"
              ><i class="el-icon-delete"></i
            ></el-link>
          </el-col>
        </el-row>
      </el-scrollbar>
      <el-scrollbar
        wrap-style="max-height: 60vh;"
        v-else-if="activeName == 'edit_rule'"
      >
        <el-row>
          <span
            >These fields can't be edited if following condition satisfy:
          </span>
          <el-select
            v-model="editFilterId"
            clearable
            placeholder="Select field"
            size="mini"
          >
            <el-option
              v-for="filter in entityAllFilters"
              :key="filter._id"
              :label="filter.filter_name"
              :value="filter._id"
              >{{ filter.filter_name }}</el-option
            >
          </el-select>
        </el-row>
        <div class="cell" v-if="activeName === 'edit_rule'">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox>

        </div>
        <el-row>
          <el-checkbox-group v-model="editableFields" @change="handleCheckedFieldsChange">
            <ul>
              <li
                v-for="(op, i) in currentEntityFields"
                :key="i + '_' + op.key"
              >
                <el-checkbox :label="op.key">
                  {{ op.label }}
                </el-checkbox>
              </li>
            </ul>
          </el-checkbox-group>
        </el-row>
      </el-scrollbar>
      <el-row class="mb-2 mt-20">
        <el-button size="mini" class="float-right ml-3" @click="close">
          Cancel
        </el-button>
        <el-button
          size="mini"
          class="float-right"
          type="primary"
          @click="saveRules"
          :disabled="checkDisabled"
        >
          Save
        </el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutomationHelper from "@/mixins/AutomationHelper";

export default {
  props: ["entity_id", "selectedEntityId", "currentEntity"],
  mixins: [AutomationHelper],
  data() {
    return {
      checkAll:false,
      isIndeterminate: false,
      permissionsLoading: false,
      deleteRules: [],
      entityFields: [],
      entityRelations: [],
      deleteCondition: "AND",
      allTemplateFieldMap: {},
      allTemplates: [],
      activeName: "delete_rule",
      entityAllFilters: [],
      editFilterId: "",
      currentEntityFields: [],
      editableFields: [],
    };
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById", "getAllEntities"]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    checkDisabled() {
      if (!this.deleteRules.length) {
        return false;
      }
      return this.deleteRules.find((e) => {
        if (!e.type || !e.message) {
          return true;
        } else if (e.type == "FIELD") {
          return !e.field;
        } else if (e.type == "RELATIONSHIP") {
          return !e.relation;
        }
      })
        ? true
        : false;
    },
  },
  async mounted() {
    if (this.selectedEntityId) {
      this.permissionsLoading = true;
      await Promise.all([
        this.$store.dispatch("entities/fetchEntities", {
          get_all: true,
          populateData: true,
        }),
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: this.selectedEntityId,
        }),
        this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
          get_all: true,
          // include_standard: true,
          // populateData: true,
        }),
        this.$store.dispatch("filters/fetchAllEntityFilters", {
          entityId: this.selectedEntityId,
        }),
      ]);
      if (
        this.getAllCompanyTemplatesData &&
        this.getAllCompanyTemplatesData.data
      ) {
        this.extractFieldsFromEntity();
      }
      if (this.currentEntity?.delete_rules) {
        this.deleteRules = this.currentEntity.delete_rules;
      }
      if (this.currentEntity?.edit_filter) {
        this.editFilterId = this.currentEntity.edit_filter;
      }
      if(this.currentEntity?.editable_fields){
        this.editableFields = this.currentEntity.editable_fields
      }
      if(this.currentEntity?.delete_condition){
        this.deleteCondition = this.currentEntity.delete_condition
      }
      if (this.currentEntity?.templates) {
        this.currentEntity.templates.forEach((e) => {
          let temp = this.allTemplates.find((el) => el._id == e.template_id);
          this.currentEntityFields = [
            ...this.currentEntityFields,
            ...this.getFieldsFromTemplate(temp, false, false),
          ];
        });
      }
      this.permissionsLoading = false;
    }
  },
  methods: {
    handleCheckAllChange(val) {
      if (val) {
        this.editableFields = this.currentEntityFields.map(field => field.key);
      } else {
        this.editableFields = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedFieldsChange(value) {
      this.editableFields = value;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.currentEntityFields.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.currentEntityFields.length;
    },
    handleClick() {
      if (this.activeName == "edit_rule" && this.checkDisabled) {
        this.$message.warning("Please config delete rules");
        this.activeName = "delete_rule";
      }
    },
    close() {
      this.$emit("close");
    },
    deleteRule(i) {
      this.deleteRules.splice(i, 1);
    },
    saveRules() {
      this.$emit(
        "save",
        this.deleteRules,
        this.deleteCondition,
        this.editFilterId,
        this.editableFields
      );
    },
    addRule() {
      this.deleteRules.push({});
    },
    getEntityTemplateFields(data, entity_id) {
      let fields = [];
      let tempData = JSON.parse(JSON.stringify(data));
      tempData.sections.forEach((e) => {
        fields = [
          ...fields,
          ...e.fields.filter(
            (e) => e.inputType == "ENTITY" && e.entity_id == entity_id
          ),
        ];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        // e.label = data.name + " - " + e.label;
        if (e.key && !e.key.includes("#")) {
          e.key = e.template_id + "#" + e.key;
        }
        return e;
      });
      //have to check data table fields
      let dataTableFields = fields.filter((e) => e?.inputType == "DATA_TABLE");
      if (dataTableFields && dataTableFields.length) {
        dataTableFields.forEach((fl) => {
          fields = [
            ...fields,
            ...fl?.data_table_columns
              .filter(
                (e) => e.inputType == "ENTITY" && e.entity_id == entity_id
              )
              .map((e) => {
                e.data_table_key = fl.key;
                e.template_id = data._id;
                e.label = fl.label + "-" + e.label;
                e.key = e.data_table_key + ":" + e.key;
                return e;
              }),
          ];
        });
      }
      return fields;
    },
    extractFieldsFromEntity() {
      this.permissionsLoading = true;
      this.entityFields = [];
      this.entityRelations = [];
      if (this.getAllCompanyTemplatesData?.data) {
        this.allTemplates = this.getAllCompanyTemplatesData.data;
        (this.allTemplates || []).forEach((template) => {
          this.allTemplateFieldMap[template._id] = {
            name: template.name,
            id: template._id,
            fields: this.getEntityTemplateFields(
              template,
              this.selectedEntityId
            ),
          };
        });
      }
      this.getAllEntities.data.forEach((en) => {
        if (en?.templates) {
          en.templates.forEach((t) => {
            let fieldObj = this.allTemplateFieldMap[t.template_id] || {};
            if (fieldObj?.fields) {
              this.entityFields = [
                ...this.entityFields,
                ...fieldObj.fields.map((f) => {
                  f.label = en.name + "-" + f.label;
                  f.p_entity_id = en._id;
                  return f;
                }),
              ];
            }
          });
        }
      });
      this.getEntityRelationships.forEach((e) => {
        if (e?.child_entity_id == this.selectedEntityId) {
          this.entityRelations.push({
            _id: e._id,
            relationshiop_id: e.parent_entity_id,
            relationshiop_title: e.parent_relation_title,
          });
        } else if (e?.parent_entity_id == this.selectedEntityId) {
          this.entityRelations.push({
            _id: e._id,
            relationshiop_id: e.child_entity_id,
            relationshiop_title: e.child_relation_title,
          });
        }
      });
      this.entityAllFilters = [...this.getAllEntityFilters];
      this.permissionsLoading = false;
    },
  },
};
</script>

<style></style>
